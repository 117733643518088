import {
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withPaths, withSeries } from '../contexts/data-context'
import Helper from '../utils/Helper'
import { DeleteEpisode } from './Delete'
import { CancelButton, DeleteButton, OutlinedField, SaveButton } from './Forms'
import { DangerZone } from './Grids'
import CardControl from './ImageControl'
import { assetServer } from '../utils/url'
import PdfUploadField from './PdfUploadField'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listTextField: {
    width: 'calc(100% - 50px)', // We give place to the icon
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '0',
  },
})

const EpisodeForm = (props: any) => {
  const handleCancel = () => {
    if (props.isVisitor) {
      props.history.push('/episodes-visitor')
    } else {
      props.history.push('/episodes')
    }
  }

  const [state, setState] = useState<any>({
    series: [],
    filteredSeries: [],
    loading: false,
    handbookFile: '',
  })

  const fetchSeries = async () => {
    const { episode } = props
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }))
    const series = await Helper.ApiRequest('/series')
    setState((prevState) => ({
      ...prevState,
      series,
    }))

    const filteredSeries = editMode
      ? series.filter(
          (oneSeries) =>
            episode.path && oneSeries.path._id === episode.path._id,
        )
      : series

    setState((prevState) => ({
      ...prevState,
      filteredSeries,
      loading: false,
    }))
  }

  useEffect(() => {
    fetchSeries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (e) => {
    // We don't want submit to refresh the page
    e.preventDefault()

    const { onSave, episode }: any = props

    try {
      if (state.handbookFile) {
        episode.handbook = await handleUploadPdf(state.handbookFile)
      }
      if (episode.formState) {
        episode.state = episode.formState
        delete episode.formState
      }
    } catch (error) {
      console.log(error)
    }

    return onSave()
  }

  const handleAfterDelete = () => {
    const { history } = props
    history.push('/episodes')
  }

  const handleChangeEduTargets = (index) => (event) => {
    const { episode, onChange } = props
    episode.eduTargets[index] = event.target.value
    onChange(episode)
  }

  const handleDeleteEduTargets = (index) => () => {
    const { episode, onChange } = props
    // Remove the element at index
    episode.eduTargets.splice(index, 1)
    onChange(episode)
  }

  const handleAddEduTarget = () => {
    const { episode, onChange } = props
    const { eduTargets } = episode
    const last = eduTargets[eduTargets.length - 1] || ''
    // Don't add if the last one is already empty
    if (eduTargets.length && last.trim().length === 0) return
    eduTargets.push('')
    episode.eduTargets = eduTargets
    onChange(episode)
  }

  const handleChange = (field) => (event) => {
    const { episode, onChange } = props
    onChange({
      ...episode,
      [field]: event.target.value,
    })
  }

  const handleChangeChecked = (field) => (event) => {
    const { episode, onChange } = props
    episode[field] = event.target.checked
    if (field === 'isAnon') {
      episode.isFree = false
      episode.order = 0
    }
    onChange(episode)
  }

  const handleChangeLevel = (event) => {
    event.preventDefault()
    const { episode, onChange, paths } = props
    const seriesData = state.series
    episode.path = paths.find((e) => e._id === event.target.value)

    onChange(episode)
    const filteredSeries = seriesData.filter(
      (oneSeries) => oneSeries.path._id === episode.path._id,
    )
    setState((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      filteredSeries,
    }))
  }

  const handleChangeSeries = (event) => {
    const { episode, onChange } = props
    const seriesData = state.series

    episode.series = seriesData.find((e) => {
      return e._id === event.target.value
    })
    onChange(episode)
  }

  const handleChangeUrl = (field) => (value) => {
    const { episode, onChange } = props
    episode[field] = value
    onChange(episode)
  }

  const handleUploadThumbnail = () => {
    const { onSave } = props
    onSave()
  }

  const handleAnon = () => () => {
    props.enqueueSnackbar('You cannot change this field', {
      variant: 'warning',
    })
  }

  const handlePdfFileUpload = (event) => {
    const uploadedFile = event.target.files[0]
    setState((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      handbookFile: uploadedFile,
    }))
  }

  const cancelUploadPdf = () => {
    const { onChange, episode } = props

    if (state.handbookFile) {
      setState((prevState) => ({
        ...prevState, // Spread the previous state to retain other properties
        handbookFile: null,
      }))
    }

    if (episode.handbook) {
      episode.handbook = ''
      onChange(episode)
    }
  }

  const handleUploadPdf = async (file) => {
    const uploadName = 'pdf/' + file.name
    const formData = new FormData()
    formData.append('upload', file)
    await Helper.uploadAsset(uploadName, formData)
    return assetServer + uploadName
  }

  const { classes, paths, episode, editMode = false, isVisitor = false } = props
  const seriesData = state.filteredSeries

  const {
    title = '',
    slug = '',
    order = '',
    path = '',
    description = '',
    formState = episode?.state ?? 'draft',
    eduTargets = [],
    thumbnail = '',
    image = '',
    isFree = isVisitor,
    isAnon = false,
    series = '',
    handbook = '',
  } = episode
  const textProps = {
    classes,
    handleChange,
  }
  const checkProps = {
    classes,
    handleChange: handleChangeChecked,
  }
  const checkAnonProps = {
    classes,
    handleChange: editMode ? handleAnon : handleChangeChecked,
  }
  const pathProps = {
    classes,
    onChange: handleChangeLevel,
    paths,
    id: (path && path._id) || '',
  }
  const seriesProps = {
    classes,
    onChange: handleChangeSeries,
    seriesData,
    id: (series && series._id) || '',
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {!state.loading ? (
        <Grid container spacing={24} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={2}>
            <TitleField {...textProps} value={title} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <SlugField {...textProps} value={slug} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <OrderField
              {...textProps}
              value={isAnon ? 0 : order}
              disabled={isAnon}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            {!isVisitor && <LevelField {...pathProps} />}
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <StateField {...textProps} value={formState} />
          </Grid>
          {!isVisitor && (
            <>
              <Grid item xs={12} sm={3} md={2}>
                <IsFreeField
                  {...checkProps}
                  isFree={isAnon ? false : isFree}
                  disabled={isAnon}
                />
                <IsAnonField
                  {...checkAnonProps}
                  isAnon={isAnon}
                  editMode={editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <SeriesField {...seriesProps} />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={4}>
            <Grid container direction="column">
              <DescriptionField {...textProps} value={description} />
              <EduTargetsField
                classes={classes}
                eduTargets={eduTargets}
                onAdd={handleAddEduTarget}
                handleChange={handleChangeEduTargets}
                handleDelete={handleDeleteEduTargets}
              />
            </Grid>
          </Grid>

          {!isVisitor && (
            <Grid item xs={12} sm={6} md={4}>
              <CardControl
                url={thumbnail}
                title="Thumbnail"
                onUpload={handleUploadThumbnail}
                onChangeUrl={handleChangeUrl('thumbnail')}
                uploadDefaultPrefix="thumbs"
                uploadDefaultName={`th-${slug}`}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <CardControl
              url={image}
              title="Background"
              onUpload={handleUploadThumbnail}
              onChangeUrl={handleChangeUrl('image')}
              uploadDefaultPrefix="bgs"
              uploadDefaultName={`bg-${slug}`}
            />
          </Grid>

          {!isAnon && !isVisitor && (
            <Grid item xs={12} sm={6}>
              <PdfUploadField
                classes={classes}
                onUpload={handlePdfFileUpload}
                title={'Handbook'}
                value={state.handbookFile ? state.handbookFile.name : handbook}
                url={state.handbookFile || handbook}
                onCancel={cancelUploadPdf}
              />
            </Grid>
          )}

          <Grid container item xs={12}>
            <Grid item xs={12} sm={3} md={2}>
              <SaveButton onClick={handleSave} />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <CancelButton onClick={handleCancel} />
            </Grid>
          </Grid>
          {episode._id && (
            <DangerZone>
              <DeleteEpisode
                episode={episode}
                onAfterDelete={handleAfterDelete}
                component={DeleteButton}
              />
            </DangerZone>
          )}
        </Grid>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </form>
  )
}
EpisodeForm.defaultProps = {}
EpisodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  episode: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withSeries(
  withPaths(withSnackbar(withRouter(withStyles(styles)(EpisodeForm)))),
)

const TitleField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Title"
      className={classes.textField}
      value={value}
      onChange={handleChange('title')}
      required
    />
  )
}

const SlugField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Slug"
      className={classes.textField}
      value={value}
      onChange={handleChange('slug')}
      required
    />
  )
}

const OrderField = ({ classes, handleChange, value, disabled }) => {
  return (
    <OutlinedField
      label="Order"
      className={classes.textField}
      value={value}
      onChange={handleChange('order')}
      disabled={disabled}
      required
    />
  )
}

const DescriptionField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Description"
      className={classes.textField}
      value={value}
      onChange={handleChange('description')}
      multiline
      // rowsMax="4"
      rows="4"
    />
  )
}

const LevelField = ({ classes, id, paths, onChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="level-simple">Level</InputLabel>
      <Select
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'level-simple',
        }}
      >
        {paths?.map((p) => {
          return (
            <MenuItem key={p._id} value={p._id}>
              {p.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const SeriesField = ({ classes, id, seriesData, onChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="series-simple">Series</InputLabel>
      <Select
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'series-simple',
        }}
      >
        {Array.isArray(seriesData) &&
          seriesData.map((oneSeries) => {
            return (
              <MenuItem key={oneSeries._id} value={oneSeries._id}>
                {`${oneSeries.title} (${oneSeries.path.title})`}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}

const StateField = ({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="state-simple">State</InputLabel>
      <Select
        value={value}
        onChange={handleChange('formState')}
        inputProps={{
          id: 'state-simple',
        }}
      >
        <MenuItem value="published">Published</MenuItem>
        <MenuItem value="draft">Draft</MenuItem>
      </Select>
    </FormControl>
  )
}
const IsFreeField = ({ classes, isFree, handleChange, disabled }) => {
  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            checked={isFree}
            onChange={handleChange('isFree')}
            value="isFree"
            disabled={disabled}
          />
        }
        label="Free"
      />
    </FormControl>
  )
}
const IsAnonField = ({ classes, isAnon, handleChange }: any) => {
  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            checked={isAnon}
            onChange={handleChange('isAnon')}
            value="isAnon"
          />
        }
        label="For Anonymous"
      />
    </FormControl>
  )
}
const EduTargetsField = ({
  classes,
  eduTargets,
  handleChange,
  handleDelete,
  onAdd,
}) => {
  return (
    <List subheader={<EduTargetsHeader onClick={onAdd} />}>
      {eduTargets.map((eduTarget, index) => {
        return (
          <ListItem dense key={index}>
            <ListItemText>
              <TextField
                margin="dense"
                variant="filled"
                className={classes.listTextField}
                value={eduTarget}
                onChange={handleChange(index)}
              />
              <IconButton aria-label="Delete" onClick={handleDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemText>
          </ListItem>
        )
      })}
    </List>
  )
}

const EduTargetsHeader = ({ onClick }) => {
  return (
    <ListSubheader>
      Educational Targets
      <IconButton onClick={onClick}>
        <AddIcon />
      </IconButton>
    </ListSubheader>
  )
}
