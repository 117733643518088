import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withPaths } from '../../contexts/data-context'
import { OutlinedField } from '../Forms'
import Helper from '../../utils/Helper'
import { assetServer } from '../../utils/url'
import UploadField from './UploadField'
import styles from './CategoryFormStyles'
import ButtonSection from './ButtonSection'

const CategoryForm = (props: any) => {
  const { classes, paths, category, onChange, onSave, editMode } = props
  const [parentCategory, setParentCategory] = useState([])
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileUrl, setFileUrl] = useState('')

  const fetchData = useCallback(async () => {
    const data = await Helper.ApiRequest('/categories')
    setParentCategory(data)
  }, [setParentCategory])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (category && category.iconUrl) {
      setFileUrl(category.iconUrl)
    }
  }, [category])

  const handleFileUpload = (event) => {
    if (fileUrl) {
      URL.revokeObjectURL(fileUrl)
    }
    if (editMode) {
      onChange({ fileUrl: URL.createObjectURL(event.target.files[0]) })
    }
    setFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
  }

  const handleSave = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      if (file) category.iconUrl = await handleUploadImage()
      onChange(category)
      await onSave(parentCategory.filter(({ _id }) => _id !== category._id))
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleUploadImage = async () => {
    const uploadName = 'thumbs/' + file.name
    const formData = new FormData()
    formData.append('upload', file)
    await Helper.uploadAsset(uploadName, formData)
    return assetServer + uploadName
  }

  const handleChange = (field) => (event) => {
    if (category) {
      category[field] = event.target.value
      onChange(category)
    }
  }

  const handleFilter = ({ _id }) => {
    if (category && !category._id) return true
    return (
      category.childCategories &&
      !category.childCategories.includes(_id) &&
      _id !== category._id
    )
  }

  const onCancel = () => {
    props.history.push('/categories')
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        className={classes.container}
        container
        spacing={8}
        direction="column"
      >
        <Grid direction="column" container className={classes.container}>
          <Typography className={classes.title}>Age Group</Typography>

          <Select
            className={classes.textField}
            value={category.ageGroup || ''}
            onChange={handleChange('ageGroup')}
            input={<OutlinedInput labelWidth={0} />}
          >
            <MenuItem value={null} />
            {Array.isArray(paths) && paths.length > 0 ? (
              paths?.map((path) => (
                <MenuItem key={path._id} value={path._id}>
                  {path.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Data</MenuItem>
            )}
          </Select>
        </Grid>
        <Grid direction="column" container className={classes.container}>
          <Typography className={classes.title}>Category Name *</Typography>
          <OutlinedField
            className={classes.textField}
            value={category.title || ''}
            onChange={handleChange('title')}
          />
        </Grid>
        <Grid direction="column" container className={classes.container}>
          <Typography className={classes.title}>Parent Category</Typography>
          <Select
            className={classes.textField}
            value={category.parentCategory || ''}
            onChange={handleChange('parentCategory')}
            input={<OutlinedInput labelWidth={3} />}
          >
            <MenuItem value={null} />
            {Array.isArray(parentCategory) && parentCategory.length > 0 ? (
              parentCategory.filter(handleFilter).map((data) => (
                <MenuItem key={data._id} value={data._id}>
                  {data.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Data</MenuItem>
            )}
          </Select>
        </Grid>
        <UploadField
          classes={classes}
          onUpload={handleFileUpload}
          value={file ? file.name : fileUrl}
        />
        <ButtonSection
          classes={classes}
          onSave={handleSave}
          loading={loading}
          onCancel={onCancel}
          editMode={editMode}
        />
      </Grid>
    </form>
  )
}

export default withPaths(withRouter(withStyles(styles)(CategoryForm)))
